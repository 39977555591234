import React from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";
import Icon from "./Icon";

const PolicyDialog = ({
  onHide,
  header,
  htmlContent,
  closeText,
  extraInfo,
  ...restProps
}) => {
  return (
    <Modal
      {...restProps}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      left
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-auto">
        <div dangerouslySetInnerHTML={{ __html: `${htmlContent}` }} />
      </Modal.Body>
      <Modal.Footer>
        <div className="mx-auto">
          <Button variant="primary" onClick={onHide}>
            <Icon iconName="CloseIcon" />
            &nbsp; {closeText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

PolicyDialog.propTypes = {
  onHide: PropTypes.func,
  header: PropTypes.string.isRequired,
  htmlContent: PropTypes.string,
  closeText: Proptypes.string,
  extraInfo: Proptypes.any,
};

PolicyDialog.defaultProps = {
  onHide: null,
  closeText: "Close",
};

export default PolicyDialog;
