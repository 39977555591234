import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import ImageCard2 from "components/ImageCard2";
import PageSection from "components/PageSection";
import * as SocialIcons from "components/SocialIcons";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { 
    anchor, header, subheader, contactImageFileName,
    social: { twitter },
   } = frontmatter;

  const extraInfoPart = (
    <SocialIcons.Twitter 
      font-size="40px"
      width="80px"
      height="80px"
      userName={twitter} 
    />
  );

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <ImageCard2
            imageFileName={contactImageFileName}
            header={header}
            subheader={subheader}
            extraInfo={extraInfoPart}
          />
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
