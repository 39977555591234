import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import PolicyItem from "components/PolicyItem";

const Footer = ({ frontmatter, privacyNode, termsNode }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    social: { facebook, github, linkedin, medium, twitter },
  } = frontmatter;
/*
  <Col lg={3} className="my-3 my-lg-0">
    {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
  </Col>
*/

  return (
    <footer className="footer py-3">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={5} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={7} className="text-lg-right">
            <PolicyItem
              policyNode={privacyNode}
              className="mr-3"
            />
            <PolicyItem
              policyNode={termsNode}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
  privacyNode: PropTypes.any,
  termsNode: PropTypes.any,
};

Footer.defaultProps = {
  frontmatter: null,
  privacyNode: null,
  termsNode: null,
};

export default Footer;
