import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import Objective from "components/Objective";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Mission = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, objectives } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        {objectives.map((objective) => (
          <Col md={4} key={objective.header}>
            <Objective {...objective} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Mission.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Mission.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Mission;
