import React from "react";
import PropTypes from "prop-types";

//import { Col } from "react-bootstrap";
//import Icon from "components/Icon";
import PolicyDialog from "components/PolicyDialog";

//import "./PolicyItem.scss";

const PolicyItem = ({
  policyNode,
  className,
  extraInfo,
}) => {
  const {
    html,
    frontmatter: { 
      policy: { contentTag, header, linkText, closeText },
    },
  } = policyNode;

  const [showDetail, setShowDetail] = React.useState(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail(true);
  }, []);
  const handleHideDetail = React.useCallback(() => {
    setShowDetail(false);
  }, []);

  return (
    <>
      <a
          tabIndex={-1}
          className={className}
          data-toggle="modal"
          onClick={handleShowDetail}>
          {linkText}
      </a>
      <PolicyDialog
        show={showDetail}
        onHide={handleHideDetail}
        header={header}
        htmlContent={html}
        closeText={closeText}
        extraInfo={extraInfo}
      />
    </>
  );
};

PolicyItem.propTypes = {
  policyNode: PropTypes.object.isRequired,
  className: PropTypes.string,
  extraInfo: PropTypes.any,
};

PolicyItem.defaultProps = {
  className: "",
};

export default PolicyItem;
